import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ErayAvatar from '../assets/images/Eray_Agarer.jpg';
import '../assets/styles/Main.scss';

function Main() {

  return (
    <div className="container">
      <div className="about-section">
        <div className="image-wrapper">
          {/* <img src={ErayAvatar} alt="Avatar" /> */}
          <img
            src={ErayAvatar}
            alt="Avatar"
            style={{
              filter: 'grayscale(100%)',
              // width: '150px',
              // height: '150px'
            }}
          />
        </div>
        <div className="content">
          <div className="social_icons">
            <a href="https://github.com/eagarer" target="_blank" rel="noreferrer"><GitHubIcon /></a>
            <a href="https://www.linkedin.com/in/eray-agarer/" target="_blank" rel="noreferrer"><LinkedInIcon /></a>
          </div>
          <h1>ERAY AGARER</h1>
          <p>Software Engineer</p>

          <div className="mobile_social_icons">
            <a href="https://github.com/eagarer" target="_blank" rel="noreferrer"><GitHubIcon /></a>
            <a href="https://www.linkedin.com/in/eray-agarer/" target="_blank" rel="noreferrer"><LinkedInIcon /></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;